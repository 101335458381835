import { render, staticRenderFns } from "./PageHowTo.vue?vue&type=template&id=bc391ff0&scoped=true&"
import script from "./PageHowTo.vue?vue&type=script&lang=js&"
export * from "./PageHowTo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc391ff0",
  null
  
)

export default component.exports